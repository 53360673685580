/* global Component, axios, _ */
class selectLinkToComponent extends Component {

    static name() {
        return "selectLinkToComponent";
    }

    static componentName() {
        return "selectLinkToComponent";
    }

    getProps() {
        return {
            disabled:{
                type:Boolean,
                required: false,
                default: false,
            },
            editable:{
                type:Boolean,
                required: false,
                default: false,
            },
            table: {
                type: String,
                required: true,
            },
            paste: {
                type: String,
                required: true,
            },
            showField: {
                type: String,
                required: true,
            },
            selected: {
                type: String,
                default: null,
            },
            delayLoad:{
                type:Boolean,
                default:false,
            },
            filters: {
                type: Object,
            },
            callback: {
                type: Function
            },
            recordKey:{
                type:String,
                default:''
            },
            rowField:{
                type:String,
                default:''
            }
        };
    }

    data() {
        return {
            linkToData: {},
            mustUpdate: false,
            suggestionsList:[],
            selectedValueData:null,
            searchValue:null,
            loading: false,
        };
    }

    created() {
        return async function () {
            if(this.delayLoad) {
                this.lazyLoadLinkToData(this.selected, this.loading);
            } else {
                this.loadLinkToData();
            }
        };
    }

    getWatch() {
        return{
            'filters': function (newValue,oldValue) {
                if (JSON.stringify(newValue) != JSON.stringify(oldValue) && !this.delayLoad) {
                    this.loadLinkToData();
                }
            },
            'selected': function (newValue,oldValue) {
                if(newValue != this.selectedValueData)
                    this.updateSelectLinkto(newValue);
            },
        };
    }

    getComputed() {
        return {
            showEditableInput() {
                return this.editable && Object.keys(this.linkToData).length == 0;
            },
            OurSettingsCountry: function () {
                return this.$store.getters.getOurSettingsCountry;
            },
            selectedValue() {
                if(this.selected == null) {
                    if(this.selectedValueData)
                        return this.selectedValueData;
                    let storeSelected = this.$store.getters.getSelectLinkToValue(this.recordKey + '_' + this.table);
                    this.mustUpdate = false;
                    if (this.table === 'Country' && storeSelected === "") {
                        return this.OurSettingsCountry;
                    } else if (storeSelected) {
                        return storeSelected;
                    }
                }
                return this.selected;
            },
            selectOptions: function () {
                return  Object.values(this.linkToData);
            }
        };
    }

    getMethods() {
        return {
            loadLinkToData: this.loadLinkToData,
            lazyLoadLinkToData: this.lazyLoadLinkToData,
            getOptionLabel: this.getOptionLabel,
            updateSelectLinkto: this.updateSelectLinkto,
        };
    }

    loadLinkToData() {
        this.loading = true;
        axios.post('/ecommerce/api/getLinkToData', {
            table: this.table,
            paste: this.paste,
            showField: this.showField,
            filters: this.filters
        })
            .catch(error=> this.loading = false)
            .then(response => {
                let linkToData = response.data.result;
                linkToData = _.map(linkToData, x => {
                    return Object.assign(x, x.fields, x.virtualFields);
                });
                if(Array.from(linkToData.values()).filter(x=>x[this.paste] === this.selected).length > 0)
                    this.selectedValueData = this.selected;
                this.linkToData = Object.seal(linkToData);
                this.loading = false;
            });
    }

    lazyLoadLinkToData(search, loading) {
        if(search.length > 3) {
            this.loading = true;
            const filters = this.filters;
            filters[`${this.showField}__like`] =  `%${search}%`;

            axios.post('/ecommerce/api/getLinkToData', {
                table: this.table,
                paste: this.paste,
                showField: this.showField,
                filters: filters,
                selected: this.selected
            })
                .catch(error=> this.loading = false)
                .then(response => {
                    let linkToData = response.data.result;
                    linkToData = _.map(linkToData, x => {
                        return Object.assign(x, x.fields, x.virtualFields);
                    });
                    if(Array.from(linkToData.values()).filter(x=>x[this.paste] === this.selected).length > 0)
                        this.selectedValueData = this.selected;
                    this.linkToData = Object.seal(linkToData);
                    this.loading = false;
                });
        }
    }

    getOptionLabel(option){
        let label = "";
        if(Array.isArray(this.showField)) {
            for(let recField of this.showField)
                label = label + " " + option[recField];
        }
        else
            label = option[this.showField];
        //console.log("Select Label",label);
        return label;
    }

    updateSelectLinkto(value) {
        let data = {
            recordKey:this.recordKey,
            table: this.recordKey ? this.recordKey + '_' + this.table : this.table,
            linkToData:this.linkToData.filter((x)=>x[this.paste] == value),
            value: value
        };
        this.selectedValueData = value;
        this.$store.dispatch('updateSelectLinkto', data);
        this.$emit('update-value', value);
        this.$emit('update-value-full', data);
        this.$emit('input',  value);
        this.mustUpdate = true;
    }

    getTemplate() {
        return `<div :key="'container'+recordKey+'_'+table" :showEditableInput="showEditableInput">
                    <template v-if="delayLoad">
                        <v-select 
                            :loading="loading"
                            :clearable="true" 
                            :searchable="true"
                            :filterable="true"
                            :options="selectOptions"
                            :key="recordKey+'_'+table"
                            :id="recordKey+'_'+rowField+'_'+table"
                            :disabled="disabled ? 'disabled' : null"
                            :reduce="record => record[paste]"
                            :label="showField"
                            class="form-control"
                            v-model="selectedValueData"
                            @search="lazyLoadLinkToData"
                            @input="updateSelectLinkto"
                        >
                            <div slot="no-options">{{tr("Sorry, no matching options")}}</div>
                        </v-select>
                    </template>
                    <template v-else>
                        <v-select 
                            :loading="loading"
                            :clearable="true" 
                            :searchable="true"
                            :filterable="true"
                            :options="selectOptions"
                            :key="recordKey+'_'+table"
                            :id="recordKey+'_'+rowField+'_'+table"
                            :disabled="disabled ? 'disabled' : null"
                            :reduce="record => record[paste]"
                            :label="showField"
                            class="form-control"
                            v-model="selectedValueData"
                            @input="updateSelectLinkto"
                        >
                            <div slot="no-options">{{tr("Sorry, no matching options")}}</div>
                        </v-select>

                    </template>
                </div>`;
    }
}

selectLinkToComponent.registerComponent();